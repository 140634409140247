<template>
  <v-container tag="section" fluid>
    <base-material-card
      color="primary"
      icon="mdi-bank"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Governança</div>
      </template>
      <v-row class="mx-auto">
        <v-col cols="12"> </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            dark
            elevation="2"
            relative
            text
            medium
            @click="downloadManualIndger()"
          >
            <v-icon left size="30px"> mdi-file-pdf-box </v-icon>
            Manual da Ferramenta
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            dark
            elevation="1"
            relative
            text
            medium
            @click="downloadResponsaveisTemas()"
          >
            <v-icon left size="30px"> mdi-microsoft-excel </v-icon>
            Responsáveis por Tema & Manual ANEEL
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mx-auto mt-5">
        <v-col cols="12">
          <h3>Responsáveis por Tema</h3>
        </v-col>
        <v-col cols="12">
          <v-data-table
            class="relatorios-historico-table"
            :headers="[
              {
                text: 'Tema INDGER',
                value: 'tema',
              },
              {
                text: 'Indicadores',
                value: 'indicadores',
              },
              {
                text: 'Validadores',
                value: 'validadores',
              },
              {
                text: 'Area',
                value: 'area',
              },
              {
                text: 'Gerência',
                value: 'gerencia',
              },
              {
                text: 'Superintendência',
                value: 'superintendencia',
              },
              {
                text: 'Diretoria',
                value: 'diretoria',
              },
            ]"
            :items="dadosResponsaveis.responsaveis"
            :options="{ itemsPerPage: -1 }" 
            :headerProps="{ sortByText: 'Ordenar Por' }"
            :footer-props="{ itemsPerPageText: 'Itens por Página' }"
            no-data-text="Nenhum relatório encontrado"
            no-results-text="Nenhum registro correspondente encontrado"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
          >
            <template v-slot:[`item.tema`]="{ item }">
              {{ item.tema }}
            </template>
            <template v-slot:[`item.indicadores`]="{ item }">
              {{ item.indicadores }}
            </template>
            <template v-slot:[`item.validadores`]="{ item }">
              {{ item.validadores }}
            </template>
            <template v-slot:[`item.area`]="{ item }">
              {{ item.area }}
            </template>
            <template v-slot:[`item.gerencia`]="{ item }">
              {{ item.gerencia }}
            </template>
            <template v-slot:[`item.superintendencia`]="{ item }">
              {{ item.superintendencia }}
            </template>
            <template v-slot:[`item.diretoria`]="{ item }">
              {{ item.diretoria }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="mx-auto">
        <v-col cols="12">
          <h3>Responsáveis por Serviço</h3>
        </v-col>
        <v-col cols="12">
          <v-data-table
            class="relatorios-historico-table"
            :headers="[
              {
                text: 'Código Serviço',
                value: 'codigo',
              },
              {
                text: 'Responsáveis',
                value: 'responsaveis',
              },
            ]"
            :items="dadosResponsaveis.entidades"
            :options="{ itemsPerPage: -1 }" 
            :headerProps="{ sortByText: 'Ordenar Por' }"
            :footer-props="{ itemsPerPageText: 'Itens por Página' }"
            no-data-text="Nenhum relatório encontrado"
            no-results-text="Nenhum registro correspondente encontrado"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
          >
            <template v-slot:[`item.codigo`]="{ item }">
              {{ item.codigo }}
            </template>
            <template v-slot:[`item.responsaveis`]="{ item }">
              {{ item.responsaveis }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import GovernancaService from "@/services/GovernancaService.js";

export default {
  name: "GovernancaIndex",
  data: () => ({
    loading: false,
    dadosResponsaveis: {
      responsaveis: [],
      entidades: [],
    },
  }),
  mounted() {
    this.loading = true;
    this.getResponsaveis();
    this.loading = false;
  },
  methods: {
    downloadManualIndger() {
      GovernancaService.getLinkDownloadManualIndger()
        .then((res) => {
          window.open(res.data.link, "_blank").focus();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Erro no download do arquivo.", "", {
            position: "topRight",
          });
        });
    },
    downloadResponsaveisTemas() {
      GovernancaService.getLinkDownloadResponsaveisTemas()
        .then((res) => {
          window.open(res.data.link, "_blank").focus();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Erro no download do arquivo.", "", {
            position: "topRight",
          });
        });
    },
    getResponsaveis() {
      GovernancaService.getResponsaveis()
        .then((res) => {
          this.dadosResponsaveis.responsaveis = res.data.data.responsaveis;
          this.dadosResponsaveis.entidades = res.data.data.entidade;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error("Erro ao buscar responsáveis!", "", {
            position: "topRight",
          });
        });
    },
  },
};
</script>

<style>
</style>