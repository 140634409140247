var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-bank","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Governança")])]},proxy:true}])},[_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","elevation":"2","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.downloadManualIndger()}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-file-pdf-box ")]),_vm._v(" Manual da Ferramenta ")],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.downloadResponsaveisTemas()}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Responsáveis por Tema & Manual ANEEL ")],1)],1)],1),_c('v-row',{staticClass:"mx-auto mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Responsáveis por Tema")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"relatorios-historico-table",attrs:{"headers":[
            {
              text: 'Tema INDGER',
              value: 'tema',
            },
            {
              text: 'Indicadores',
              value: 'indicadores',
            },
            {
              text: 'Validadores',
              value: 'validadores',
            },
            {
              text: 'Area',
              value: 'area',
            },
            {
              text: 'Gerência',
              value: 'gerencia',
            },
            {
              text: 'Superintendência',
              value: 'superintendencia',
            },
            {
              text: 'Diretoria',
              value: 'diretoria',
            } ],"items":_vm.dadosResponsaveis.responsaveis,"options":{ itemsPerPage: -1 },"headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{ itemsPerPageText: 'Itens por Página' },"no-data-text":"Nenhum relatório encontrado","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading},scopedSlots:_vm._u([{key:"item.tema",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.tema)+" ")]}},{key:"item.indicadores",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.indicadores)+" ")]}},{key:"item.validadores",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.validadores)+" ")]}},{key:"item.area",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.area)+" ")]}},{key:"item.gerencia",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.gerencia)+" ")]}},{key:"item.superintendencia",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.superintendencia)+" ")]}},{key:"item.diretoria",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.diretoria)+" ")]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Responsáveis por Serviço")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"relatorios-historico-table",attrs:{"headers":[
            {
              text: 'Código Serviço',
              value: 'codigo',
            },
            {
              text: 'Responsáveis',
              value: 'responsaveis',
            } ],"items":_vm.dadosResponsaveis.entidades,"options":{ itemsPerPage: -1 },"headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{ itemsPerPageText: 'Itens por Página' },"no-data-text":"Nenhum relatório encontrado","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading},scopedSlots:_vm._u([{key:"item.codigo",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.codigo)+" ")]}},{key:"item.responsaveis",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.responsaveis)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }